import Vue from 'vue';
import Router from 'vue-router';

Vue.use( Router );

const appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/',
            name: 'Landing',
            component: () =>
            {
                return import( /* webpackChunkName: "landing" */ '@/app/pages/Landing' );
            }
        },
        {
            path: '/prijzen',
            name: 'Rewards',
            component: () =>
            {
                return import( /* webpackChunkName: "rewards" */ '@/app/pages/Rewards' );
            }
        },
        {
            path: '/actie-ptGYdMPl4l',
            name: 'Form',
            component: () =>
            {
                return import( /* webpackChunkName: "forms" */ '@/app/pages/Form' );
            }
        }, // actie-qrkcytno4lon8u2p0
        {
            path: '/resultaat',
            name: 'Result',
            component: () =>
            {
                return import( /* webpackChunkName: "forms" */ '@/app/pages/Result' );
            }
        },
        {
            path: '/bedankt',
            name: 'Thanks',
            component: () =>
            {
                return import( /* webpackChunkName: "forms" */ '@/app/pages/Thanks' );
            }
        }
    ],
    scrollBehavior: ( to, from, savedPosition ) =>
    {
        if( to.hash )
        {
            return {
                selector: to.hash
                // , offset: { x: 0, y: 10 }
            };
        }
        return {
            x: 0,
            y: 0
        };
    }
});

appRouter.beforeEach( ( to, from, next ) =>
{
    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;
