<template>
    <div class="main-layout">
        <div class="limited">
            <header>
                <div class="container">
                    <a href="https://babybel.nl">
                        <img src="/static/img/logo-babybel.png" alt="Babybel" class="logo">
                    </a>
                </div>
            </header>
            <section class="cover">
                <babybel-video :state="coverState"></babybel-video>
            </section>
            <div class="content" id="content">
                <slot></slot>
            </div>
        </div>
        <footer>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <ul class="menu-footer">
                            <li><a href="https://babybel.nl/nl-nl/product" target="_blank">Producten</a></li>
                            <li><a href="https://babybel.nl/nl-nl/voeding" target="_blank">Voeding</a></li>
                            <li><a href="https://babybel.nl/nl-nl/entertainment" target="_blank">Say Cheese</a></li>
                            <li><a href="https://www.babybel.nl/nl-nl/goodness" target="_blank">Onze Inzet</a></li>
                        </ul>
                    </div>
                </div>
                <div class="copyright">
                    <div class="row">
                        <div class="col-12">
                            <ul class="menu-copyright">
                                <li><a href="https://babybel.nl/nl-nl/privacy-policy-bel" target="_blank">Privacy</a></li>
                                <li><a href="https://babybel.nl/nl-nl/gebruiksvoorwaarden" target="_blank">Gebruikersvoorwaarden</a></li>
                                <li><a href="https://www.babybel.nl/nl-nl/vragen-over-mini-babybel%C2%AE" target="_blank">FAQ</a></li>
                                <li><a target="_blank" href="http://www.bel-group.com/">BEL</a></li>
                                <li><a target="_blank" href="https://www.babybel.nl/nl-nl/contact-mini-babybel">Contact</a></li>
                            </ul>
                            <p class="copyright-text">&copy; Babybel 2021<span class="hidden-xs">. Alle rechten voorbehouden. Ouders: om verstikkingsgevaar voor kinderen onder 4 jaar te voorkomen, snijd in kleine stukjes. Houd kinderen altijd onder toezicht tijdens het eten.</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <!-- div class="cookies" v-show="!cookies">
            <div class="message">
                <p>Om met onze website voor jou beter ervaring te creëren, gebruiken we altijd functionele en analytische cookies (en vergelijkbare technieken).<br />
                    Ook willen we graag cookies plaatsen om je bezoek aan onze website makkelijker en persoonlijker te maken. Met deze cookies kunnen wij en derde partijen jouw internetgedrag binnen en buiten onze website volgen en verzamelen. Hiermee passen wij en derden onze website en advertenties aan op jouw interesse. We slaan je cookievoorkeuren voor je op, als je ons via een ander apparaat gebruikt, hoef je niet opnieuw een keuze te maken. Door op ‘accepteren’ te klikken ga je hiermee akkoord. Je kunt je cookievoorkeuren altijd nalezen en aanpassen via ons cookiebeleid.<br /><br />
                    <a href="https://babybel.nl" style="text-decoration: underline;">Mijn cookievoorkeuren aanpassen</a></p>
                <button style="float: right;" @click="accept">Alle cookies aanvaarden</button>
            </div>
        </div -->
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import BabybelVideo from '@/app/components/BabybelVideo';
    export default {
        components: {
            'babybel-video': BabybelVideo
        },
        data()
        {
            return {
                cookiesAccepted: false
            };
        },
        computed: {
            cookies()
            {
                return window.localStorage.getItem( 'cookies' ) || this.cookiesAccepted;
            },
            coverState()
            {
                return this.$store.getters.coverState;
            }
        },
        methods: {
            accept()
            {
                window.localStorage.setItem( 'cookies', true );
                this.cookiesAccepted = true;
            }
        }
    };
</script>
