import Vue from 'vue';
import Vuex from 'vuex';

import entry from './modules/entry';

Vue.use( Vuex );

export default new Vuex.Store({
    strict: true,

    modules: {
        entry
    },

    state: {
        coverState: 'closed'
    },

    getters: {
        coverState: ({ coverState }) => coverState
    },

    mutations: {
        coverState( state, payload )
        {
            state.coverState = payload;
        }
    },

    actions: {
        coverState: ( context, payload ) =>
        {
            context.commit( 'coverState', payload );
        }
    }
});
