<template>
    <div class="babybel-video">
        <video v-if="type === 'video'" :src="'/static/video/' + state + '.mp4'" autoplay muted playsinline></video>
        <img v-else :src="'/static/img/' + state + '-cover.jpg'" alt="" />
    </div>
</template>

<script>
    export default {
        props: {
            state: {
                type: String,
                default: 'still'
            }
        },
        computed: {
            type()
            {
                return this.state === 'closed' || this.state === 'still' ? 'static' : 'video';
            }
        }
    };
</script>

<style lang="scss">
    .babybel-video {
        line-height: 0;
        margin: 0;
        padding: 0;
        video {
            width: 100%;
            position: relative;
        }
        img {
            display: block;
        }
    }
</style>
