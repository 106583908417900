import EntryProxy from '@/app/proxies/EntryProxy';

export default {
    namespaced: true,
    state: {
        entry: {
            reward: null
        }
    },

    getters: {
        all: state => state.entry,
        medal: state =>
        {
            if( !state.entry.reward )
            {
                return 'no-win';
            }
            const obj = {
                centerparcs: 'gold',
                tas: 'silver',
                slee: 'bronze'
            };
            return obj[state.entry.reward.reward];
        }
    },

    mutations: {
        assign: ( state, payload ) =>
        {
            state.entry = Object.assign({}, state.entry, payload );
        }
    },

    actions: {
        merge: ( context, payload ) =>
        {
            context.commit( 'assign', payload );
        },

        save: ( context, payload ) =>
        {
            context.commit( 'assign', payload );

            return new EntryProxy()
                .create( context.getters.all )
                .then( response =>
                {
                    context.commit( 'assign', response );
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        },

        update: ( context, payload ) =>
        {
            context.commit( 'assign', payload );

            return new EntryProxy()
                .update( context.getters.all.id, context.getters.all )
                .then( response =>
                {
                    context.commit( 'assign', response );
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        }
    }
};
